h2{
    /*color:#404852;*/
    display: block;
    margin: 10px 0 25px 0 !important;
    border-bottom: 1px dotted #e4e9f0;
    text-align: center;
}

.login {
    padding: 2em 2em 4em 2em;
    border: 1px solid #e5e5e5;
    background-color: #f9f9f9;
    webkit-box-shadow: 0 4px 4px rgba(243,243,243,0.56);
    box-shadow: 0 4px 4px rgba(243,243,243,0.56);
}

footer{
    color: #c9302c;
    margin-top: 50px;
}

.logo{
    line-height: 50px;
    width: 90px;
    margin-top: -17px;
}

@media (min-width: 768px){
    .seperate {
        border-right: 1px solid #c6d0da;
    }
}

.captcha{
    margin-bottom: 10px;
    display: list-item;
}

.error{
    margin-top: 5px;
    color: #FF0000;

}

.user_show{
    color: #77777B;
}

.profile{
    border: 1px solid #e4e9f0;
    border-top: 6px solid #e4e9f0;
    padding: 10px;
}

.headline{
    color: #404852;
    font-weight: bold;
    border-bottom: 2px solid #72c02c;
    display: inline-block;
}

.seperate{
    border-right: 1px solid #e4e9f0;
}

.code{
    padding: 9px 14px;
    margin-top: 20px;
    margin-bottom: 14px;
    background-color: #f7f7f9;
    border: 1px solid #e1e1e8;
    border-radius: 4px;
}

ul{
    list-style: none;
}
.navbar-default-bottom{
    margin-bottom: 0;
    margin-top: 70px;
}

#sygedel_mainbundle_utilisateurs_captcha{
    margin-top: 10px;
}

.fixBody{
    padding-bottom:70px;
    padding-top:70px;
}

.pageContent{
    color: #c9302c !important;
    margin-top: 20px;
}

.espacement_label label
{
    margin-right: 5px;
}

td.montant {
    text-align: right;
}

td.alpha {
    text-align: left;
}

/*
Utilisation dans les écrans d'infos du client et de détails du contrat
*/
.fake_disabled_input {
    background-color: #eee;
    border: 1px solid #ccc;
    height: 34px;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    margin-bottom: 5px;
}




.heading-title:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    left: auto;
    right: 0;
    top: 50%;
    /*border-top: 3px double #E5E5E5;*/
}

.index-title{
    font-size: 90px;
    display: inline-block;
    text-align: center;
    font-weight: 700;
    background: #FFFFFF;
    position: relative;
}

.accueil{
    margin-top: 70px;
}

.nav-stacked li a{
    color: red;
}

.nav-stacked li.active >a{
    background:  #B73333 !important;
}

.table-tarification{
    margin-bottom: 10px;
}

.headline-title{
    color: #404852;
    font-weight: bold;
    border-bottom: 1px solid #E25041;
    display: inline-block;
}

.heading-title img {
    margin-top: 25px;
}

#slider{
    background: #B8312F;
}

.title-devis{
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ddd;
}

.table-devis-prevoyance, .table-encaissement-ponctuel, .table-info-client, .table-detail-contrat, .table-quittances, .table-rejet{
    border: 1px solid #ddd;
    font-size: 12px;
}


.sizeModal{
    font-size: 11px;
}

/*.liste_traitement_ponctuels{
    background-image: url("../fleche.png");
}*/

.rectangle_title_adoha{
    background-color: #FE9A2E;
    border: 1px solid #000;
    height: 60px;
    font-size: 18px;
    line-height: 3.42857143;
    margin-bottom: 55px;
    text-align: center;
}

.rectangle_title_sygedel{
    background-color: #BB0000;
    border: 1px solid #000;
    height: 60px;
    font-size: 18px;
    line-height: 3.42857143;
    margin-bottom: 55px;
    text-align: center;
}

.liste_traitement_ponctuels{
    font-size: 15px;
    margin-bottom: 25px;
}

.liste_menu_avenant{
    font-size: 15px;
    margin-bottom: 25px;
}

.liste_parametrage_adoha{
    font-size: 15px;
    margin-bottom: 25px;
}

.liste_parametrage_sygedel{
    font-size: 15px;
    margin-bottom: 25px;
}

.title_adoha{
    height: 60px;
    font-size: 18px;
    line-height: 3.42857143;
    margin-bottom: 55px;
    color : orange;
}

.title_sygedel{
    height: 60px;
    font-size: 18px;
    line-height: 3.42857143;
    margin-bottom: 55px;
    color : red;
}

.subtitle_adoha{
    font-size: 16px;
    margin-bottom: 20px;
    color : orange;
}


.subtitle_sygedel{
    font-size: 16px;
    margin-bottom: 20px;
    color : red;
}

.espacement_form_parametrage{
    margin-bottom: 10px;
}

.table-orange{
    background: #D35400;
    border-top: 1px solid #D35400;
    border-bottom: 1px solid #D35400;
}
.table-jaune{
    background: #F9BF3B;
    border-top: 1px solid #F9BF3B;
    border-bottom: 1px solid #F9BF3B;
}

.table-blanc{
    background: #FFFFFF;
    border-top: 1px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
}

.form-choices > input[type=radio]{
    margin-left: 50px;
}

.price-periodicite {
    float: right;
}

.bg-light {
    padding: 10px 15px;
    margin-bottom: 10px;
    background: #FFFFFF;
    border: solid 1px #e5e5e5;
}

.number{
    padding: 3px 6px;
}

.devis-prevoyance{
    width: 1250px !important;
}

.form-select{
    height: 34px;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.cacher{
    display: none !important;
}

.total{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

#tableau{
    margin-top: 30px;
}

fieldset.scheduler-border {
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
    box-shadow:  0px 0px 0px 0px #000;
}

legend.scheduler-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
    width:auto;
    border-bottom:none;
}

.backgroundInputColorError {
    background-color : #F5A9A9;
}

.paddingTopLabel30 {
    padding-top: 30px;
}

.details-devis{
    margin: 0;
    padding: 0;
}

.fom-groupe-title{
    margin-bottom: 20px;
}

h3{
    font-size: 16px;
}

.table-garantie{
    width: 20px;
}

.html-souscription{
    margin-top: 20px;
}

.info-suspens{
    margin-top: 20px;
    margin-bottom: 10px;
}

.textEditor{
    border: 1px solid #e5e6e7;
    border-radius: 1px;
    padding: 20px;
}
#nextStpeQuitMan{
    margin-top: 20px;
}

.sous-titre{
    background: transparent !important;
    padding: 10px;
    border-top: 1px solid #FF8C00;
    border-bottom: 1px solid #FF8C00 !important;
    text-transform: uppercase;
    text-align: center;
}

.choice-label{
    margin-right: 20px;
    margin-left: 5px;
}

#remise_refresh{
    width: 70px;
    margin-left: 20px;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.success, .green{
   color: #3c763d;
}

.margTopNbottom{
    margin: 10px 0;
}

#linkQuery{
    cursor: pointer;
}
#commentaire{
    cursor: pointer;
}

.bottom-10{
    margin-bottom: 10px;
}

.headline-body{
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #dddddd;
    padding: 5px;
}

.radio label.error{
    background: #FFDBDB;
    font-size: 90%;
    padding: 3px 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    text-shadow: 1px 1px 0 #FFF;
    color: #666;
    margin-left: 5px;
}

.modal {
  text-align: center;
  padding: 0!important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.produitSelect {
    margin-bottom: 50px;
}

/******************************/
/*clickableRow*/
table#pTab {
    border-collapse: collapse;
}
#pTab tr {
    border-top: 1px solid #fff;
}
#pTab tr:hover {
    background-color: #9bc7f3;
    cursor: pointer;
}

legend {
    display: block;
    padding: 8px 0;
    border-bottom: 1px dashed rgba(0,0,0,.2);
    background: #fff;
    font-size: 16px;
    font-weight: 600;
    color: #222;
    margin: 25px 0 20px;
}


.formDevisEmprunteur
{
    width:40%;
    display:inline;

}

.cbox{

    width: 30%;
    font-size: 10px;
}

.errorMessages {
    display: none;
    list-style-type: disc;
    margin: 0 10px 15px 10px;
    padding: 8px 35px 8px 30px;
    color: #B94A48;
    background-color: #F2DEDE;
    border: 2px solid #EED3D7;
    border-radius: 4px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    width: 85%;
}

.errorMessages span {
    font-weight: bold;
}